import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paramTimeToDate } from '../hooks/useBookingParams';
import { dateToQuartile } from '../lib/dayjs';

type BookingParamsState = {
  seatings: number;
  // adults: number;
  kids: number;
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  startQuartile: number;
  endQuartile: number | null;
  bookingId: string;
  changeBooking: string;
  zone: string;
  modal: string | null;
  lang: string;
  isApp: boolean;
  appVersion: string;
  name: string;
  phone: string;
  email: string;
  inTheCircus: boolean;
  ref: string;
  requestedTime: string | null;
  suggestedTime: string | null;
  selectedRelatedVenue: string | null;
  relatedVenueVariant: string | null;
  placeId: string;
  occupiedSlotSelected: boolean;
};

const initialState: BookingParamsState = {
  seatings: 2,
  // adults: 2,
  kids: 0,
  year: new Date().getFullYear().toString(),
  month: (new Date().getMonth() + 1).toString(),
  day: new Date().getDate().toString(),
  hour: '19',
  minute: '00',
  startQuartile: 0,
  endQuartile: 0,
  bookingId: '',
  changeBooking: '',
  zone: 'indoors',
  modal: null,
  lang: 'en',
  isApp: false,
  appVersion: '',
  name: '',
  phone: '',
  email: '',
  inTheCircus: false,
  ref: null,
  requestedTime: null,
  suggestedTime: null,
  selectedRelatedVenue: null,
  relatedVenueVariant: 'venueList',
  placeId: '',
  occupiedSlotSelected: false
};

const paramsSlice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    setSeatings: (state, action: PayloadAction<number>) => {
      state.seatings = action.payload;
    },
    setKids: (state, action: PayloadAction<number>) => {
      state.kids = action.payload;
    },
    setYear: (state, action: PayloadAction<string>) => {
      state.year = action.payload;
    },
    setMonth: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
    setDay: (state, action: PayloadAction<string>) => {
      state.day = action.payload;
    },
    setHour: (state, action: PayloadAction<string>) => {
      state.hour = action.payload;
    },
    setMinute: (state, action: PayloadAction<string>) => {
      state.minute = action.payload;
    },
    setStartQuartile: (state, action: PayloadAction<number>) => {
      state.startQuartile = action.payload;
    },
    setEndQuartile: (state, action: PayloadAction<number | null>) => {
      state.endQuartile = action.payload;
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.bookingId = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setChangeBooking: (state, action: PayloadAction<string>) => {
      state.changeBooking = action.payload;
    },
    setZone: (state, action: PayloadAction<string>) => {
      state.zone = action.payload;
    },
    setModal: (state, action: PayloadAction<string | null>) => {
      state.modal = action.payload;
    },
    setIsApp: (state, action: PayloadAction<boolean>) => {
      state.isApp = action.payload;
    },
    setAppVersion: (state, action: PayloadAction<string>) => {
      state.appVersion = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setInTheCircus: (state, action: PayloadAction<boolean>) => {
      state.inTheCircus = action.payload;
    },
    setRef: (state, action: PayloadAction<string>) => {
      state.ref = action.payload;
    },
    setRequestedTime: (state, action: PayloadAction<string | null>) => {
      state.requestedTime = action.payload;
    },
    setSuggestedTime: (state, action: PayloadAction<string | null>) => {
      state.suggestedTime = action.payload;
    },
    setSelectedRelatedVenue: (state, action: PayloadAction<string | null>) => {
      state.selectedRelatedVenue = action.payload;
    },
    setRelatedVenueVariant: (state, action: PayloadAction<string | null>) => {
      state.relatedVenueVariant = action.payload;
    },
    setPlaceId: (state, action: PayloadAction<string>) => {
      state.placeId = action.payload;
    },
    setOccupiedSlotSelected: (state, action: PayloadAction<boolean>) => {
      state.occupiedSlotSelected = action.payload;
    },
    resetParams: (state) => {
      return {
        ...initialState,
        isApp: state.isApp,
        appVersion: state.appVersion,
        name: state.name,
        phone: state.phone,
        email: state.email,
        inTheCircus: state.inTheCircus,
      };
    },
  },
});

export const {
  setSeatings,
  setKids,
  setYear,
  setMonth,
  setDay,
  setHour,
  setMinute,
  setStartQuartile,
  setEndQuartile,
  setBookingId,
  setLang,
  setChangeBooking,
  setZone,
  setModal,
  resetParams,
  setIsApp,
  setAppVersion,
  setName,
  setPhone,
  setEmail,
  setInTheCircus,
  setRef,
  setRequestedTime,
  setSuggestedTime,
  setSelectedRelatedVenue,
  setRelatedVenueVariant,
  setPlaceId,
  setOccupiedSlotSelected
} = paramsSlice.actions;

export default paramsSlice.reducer;
